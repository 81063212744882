import { Box, Text } from "grommet";
import Network from "@organisms/coin/single/network";
import Listings from "components/listings";
import { useCoin } from "@organisms/coin/provider";
import AirdropBadge from "@organisms/airdrop/badge";
import { Alert } from "grommet-icons";
import TodaysBest from "./todaysBest";
import DividerCircle from "./dividerCircle";

const PresaleContainer = ({ rest, children }) => (
  <Box
    round="medium"
    pad={{ horizontal: "xsmall" }}
    background="#2F3441"
    {...rest}
  >
    <Text size="xsmall">{children}</Text>
  </Box>
);

export function Name({ slug }) {
  const { name, symbol } = useCoin(slug);
  return (
    <Box direction="column">
      <Text wordBreak="break-word">{name}</Text>
      <Text color="brand" wordBreak="keep-all" margin={{ left: "xsmall" }}>
        ${symbol}
      </Text>
    </Box>
  );
}

export function MobileName({ slug }) {
  const {
    todaysBest,
    warning,
    cmc,
    coingecko,
    geckoTerminalUrl,
    name,
    symbol,
    presale,
    airdrop,
  } = useCoin(slug);

  const LastDivider =
    airdrop ||
    todaysBest ||
    slug === "1-squid" ||
    warning ||
    cmc ||
    coingecko ||
    geckoTerminalUrl;

  return (
    <Box>
      <Text wordBreak="break-word">{name}</Text>
      <Box direction="row" align="center" gap="xsmall" responsive={false} wrap>
        <Text color="dark-6" wordBreak="break-all">
          ${symbol}
        </Text>
        <DividerCircle />
        <Network slug={slug} />
        {LastDivider && <DividerCircle />}
        {(cmc || coingecko || geckoTerminalUrl) && (
          <Listings
            cmc={cmc}
            coingecko={coingecko}
            geckoTerminal={geckoTerminalUrl}
          />
        )}
        {(slug === "1-squid" || warning) && <Alert color="status-warning" />}
        {todaysBest && <TodaysBest />}
        {presale && <PresaleContainer>PRESALE</PresaleContainer>}
        {airdrop && <AirdropBadge slug={slug} />}
      </Box>
    </Box>
  );
}

export function DesktopName({ slug }) {
  const {
    todaysBest,
    warning,
    cmc,
    coingecko,
    geckoTerminalUrl,
    name,
    airdrop,
    symbol,
  } = useCoin(slug);

  const lastDivider =
    airdrop ||
    todaysBest ||
    slug === "1-squid" ||
    warning ||
    cmc ||
    coingecko ||
    geckoTerminalUrl;

  return (
    <Box>
      <Box direction="row" gap="xsmall" align="center">
        <Text size="16px" truncate>
          {name}
        </Text>
      </Box>
      <Box direction="row" gap="xsmall" height={{ min: "35px" }} align="center">
        <Text truncate color="dark-6" size="small">
          ${symbol.toUpperCase()}
        </Text>
        <DividerCircle />
        <Network slug={slug} size="small" />
        {lastDivider && <DividerCircle />}
        {(cmc || coingecko || geckoTerminalUrl) && (
          <Listings
            cmc={cmc}
            coingecko={coingecko}
            geckoTerminal={geckoTerminalUrl}
          />
        )}
        {(slug === "1-squid" || warning) && <Alert color="status-warning" />}
        {todaysBest && <TodaysBest />}
        {airdrop && <AirdropBadge slug={slug} />}
      </Box>
    </Box>
  );
}
