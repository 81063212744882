import router from "next/router";
import axios from "axios";
import useAuth from "@organisms/auth/useAuth";
import { useCoinDispatch, useWatchlisted } from "@organisms/coin/provider";
import { useNotifications } from "@cybertools/ui/es/organisms/notifications";
import { useCallback } from "react";
import firebase from "@helpers/firebase";
import useFetchPromise from "@cybertools/ui/es/hooks/useFetchPromise";
import useFetchFnPromise from "@cybertools/ui/es/hooks/useFetchFnPromise";
import login from "@organisms/auth/login";
import WatchlistAdded from "./watchlistAdded";

export function useAddWatchlist(slug) {
  const auth = useAuth();
  const coinDispatch = useCoinDispatch();
  const notifications = useNotifications();

  return useFetchFnPromise(
    {
      action: () => {
        const add = () => {
          coinDispatch((state) => ({
            ...state,
            [slug]: {
              ...state[slug],
              watchlisted: true,
              watchlists: state[slug].watchlists + 1,
            },
          }));
          return firebase
            .auth()
            .currentUser.getIdToken()
            .then((idToken) =>
              axios.put(`/api/coins/${slug}/watchlist`, {
                meta: { idToken },
              })
            )
            .then(() => {
              notifications.success(WatchlistAdded);
            })
            .catch((error) => {
              if (error.response.status === 409) return;
              notifications.generalError();
            });
        };

        if (auth.user) return add();

        return login("watchlistToggle")
          .then(add)
          .then(() => router.replace(router.asPath));
      },
    },
    [auth, slug]
  );
}

export function useRemoveWatchlist(slug) {
  const auth = useAuth();
  const coinDispatch = useCoinDispatch();
  const notifications = useNotifications();

  return useFetchFnPromise(
    {
      action: () => {
        coinDispatch((state) => ({
          ...state,
          [slug]: {
            ...state[slug],
            watchlisted: false,
            watchlists: state[slug].watchlists - 1,
          },
        }));
        return auth.user
          .getIdToken()
          .then((idToken) =>
            axios.delete(`/api/coins/${slug}/watchlist`, {
              data: { meta: { idToken } },
            })
          )
          .then(() => {
            notifications.success("Coin removed from your watchlist");
          })
          .catch((error) => {
            if (error.response.status === 409) return;
            notifications.generalError();
          });
      },
    },
    [auth, slug]
  );
}

export function useToggleWatchlist(slug) {
  const [addWatchlist, , addLoading] = useAddWatchlist(slug);
  const [removeWatchlist, , removeLoading] = useRemoveWatchlist(slug);

  const watchlisted = useWatchlisted(slug);

  const action = useCallback(
    () => (watchlisted ? removeWatchlist() : addWatchlist()),
    [removeWatchlist, addWatchlist, watchlisted]
  );

  return [action, addLoading || removeLoading];
}

export function useFetchWatchlist() {
  const auth = useAuth();
  const coinDispatch = useCoinDispatch();
  const notifications = useNotifications();

  return useFetchPromise(
    {
      enabled: auth.user,
      action: () =>
        auth.user
          .getIdToken()
          .then((idToken) =>
            axios.get("/api/me/watchlist", {
              headers: { authorization: idToken },
            })
          )
          .then((response) => {
            coinDispatch((state) => ({ ...state, ...response.data }));

            return Object.keys(response.data);
          })
          .catch((error) => {
            notifications.generalError();
            throw error;
          }),
    },
    [auth]
  );
}
