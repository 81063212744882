import { Box, Text } from "grommet";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import BadgesIcon from "@organisms/badges/icon";
import withLink from "./withLink";

const Container = withLink(Box);

export default function TodaysBest() {
  const large = useIsLarge();

  if (!large) return <BadgesIcon size="16px" type="todaysBest" />;

  return (
    <Container
      pad={{ horizontal: "8px", vertical: "2px" }}
      round
      title="Today's Best Promotion"
      direction="row"
      gap="xsmall"
      border={{ color: "#00d7e9" }}
      align="center"
    >
      <BadgesIcon size="16px" type="todaysBest" />
      <Text size="small" color="brand">
        Today's Best
      </Text>
    </Container>
  );
}
